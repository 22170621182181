import { titleCase } from 'title-case';
import { toaster } from 'evergreen-ui';
import moment from 'moment-timezone';
import _ from 'lodash';
import * as yup from 'yup';
import { getTimezoneByCountry } from 'country-timezone-list';
import { DateTime } from 'luxon';
import sc from 'states-cities-db';
import jwt_decode from 'jwt-decode';
import abbreviation from './abbreviation.json';
import CryptoJS from 'crypto-js';

import { EXPIRED_JWT_ERROR_MESSAGE } from '../constants';

export const textToEllipses = (text = '', len = 60, typeCheck = true) => {
  if (typeCheck) {
    if (text === text.toUpperCase()) {
      len = 48;
    } else if (text === text.toLowerCase()) {
      len = 68;
    }
  }

  if (text.length < len) {
    return text;
  }

  return `${text.trim().substring(0, len)}...`;
};

export function coerceBooleanString(value) {
  if (typeof value === 'string') {
    return value === 'true' ? true : false;
  }

  return value === true ? 'true' : 'false';
}

export function formatFileSize(bytes) {
  return bytes < 1000000
    ? `${Math.floor(bytes / 1000)}KB`
    : `${Math.floor(bytes / 1000000)}MB`;
}

export function normalizeArrayOfObject(array = [], idAttribute = 'id') {
  // [{ id: 1, name: 'John }] => { 1: { id: 1, name: 'John } }
  const response = {};
  array.forEach((object) => {
    response[object[idAttribute]] = object;
  });
  return response;
}

export function generateShortId(length = 6) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZ';
  let str = '';

  for (var i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }

  return str;
}

export function getTimezone() {
  return new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
    .formatToParts(new Date())
    .find(({ type }) => type === 'timeZoneName').value;
}

export function getAbbreviation(sentence) {
  return sentence
    .split(' ')
    .map((word) => word[0])
    .join('');
}

export function getRange(start, end) {
  // Array.apply(0, Array(8)).map(function() { return 1; })
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function formatEnum(enumValue) {
  return titleCase(
    String(enumValue ?? '')
      .replaceAll('_', ' ')
      .toLowerCase()
  );
}

export function formatDate(dateString) {
  let timeZone = 'utc';
  return new Date(dateString).toLocaleDateString('en-UK', {
    timeZone,
  });
}

export const formatTime = (time = new Date()) => {
  if (time === null || time === undefined || time === '') return time;

  const toISOString = new Date(time).toISOString();
  return DateTime.fromISO(toISOString, { zone: 'utc' }).toLocaleString(
    DateTime.TIME_24_SIMPLE
  );
};

export function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return String(n)
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getErrorMessage(error, id = 'Error Message') {
  let errorMessage;

  if (error?.graphQLErrors?.length > 0) {
    errorMessage = error.graphQLErrors[0].message;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage, { id });
    return errorMessage;
  }

  if (error.message) {
    errorMessage = error.message;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage, { id });
    return errorMessage;
  }

  errorMessage = 'Something went wrong. Please try again.';
  if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
  toaster.danger(errorMessage, { id });
  return errorMessage;
}

export function getHttpErrorMessage(error, id) {
  let errorMessage;

  if (error?.response) {
    // The request was made
    // the server responded with a status code that falls out of the range of 2xx
    errorMessage = error.response.data.message || error.response.data.error;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage);
    return errorMessage;
  }

  if (error?.message) {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage);
    return errorMessage;
  }

  if (error?.request) {
    // The request was made but no response was received
    errorMessage = 'Something went wrong';
    if (errorMessage === EXPIRED_JWT_ERROR_MESSAGE) return;
    toaster.danger(errorMessage);
    return errorMessage;
  }
}

export function optionsToReactSelectFormat(
  options = [],
  key = { label: '', value: '' }
) {
  if (key.label && key.value) {
    return options.map((option) => ({
      label: option[key.label],
      value: option[key.value],
    }));
  }

  throw new Error(
    'optionsToReactSelectFormat function must contain a "key" parameter with "label" and "value" properties'
  );
}

export const numberToCurrency = (x = 0, currencyIso3 = '£', hide = false) => {
  // Convert to number using Lodash
  const _x = _.toNumber(_.replace(x, /[^0-9.-]/g, ''));

  // Check if the conversion is valid
  if (_.isNaN(_x)) {
    return hide ? '0.00' : `${currencyIso3} 0.00`;
  }

  // Check if the number is negative
  const isNegative = _x < 0;

  // Get the absolute value for formatting
  const absValue = Math.abs(_x);

  // Format the number with commas
  const formattedNumber = absValue
    ? absValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '0.00';

  // Reapply the negative sign if necessary
  const result = isNegative ? `-${formattedNumber}` : formattedNumber;

  // Return the formatted currency string
  return hide ? result : `${currencyIso3} ${result}`;
};

export const transactionsToCurrency = (x = 0, currency = '€') => {
  const _x = typeof x === 'string' ? Number(x) : x;

  const result = _x
    ? _x.toFixed(2).replace(/./g, function (c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
      })
    : '0.00';

  return `${currency} ${result}`;
};

export function formatNumberInput(value) {
  return value.replace(/[^0-9]/g, '');
}

export function formatIntegerInput(value) {
  if (value === '') return '';
  let _value = value.replace(/[^0-9]/g, '');

  const [first, second] = _value;

  if (first === '0' && second) _value = _value.substring(1);
  return _value;
}

export function formatFloatInput(value) {
  let _value = value;

  if (_value === '') return '';

  const [first, second] = _value;

  if (first === '.') {
    _value = '0' + _value;
  }

  if (first === '0') {
    if (second === '0') {
      return _value.substring(1);
    }

    if (second?.match(/[0-9]/)) {
      _value = _value.substring(1);
    }
  }

  const decimalPos = _value.indexOf('.');

  if (decimalPos >= 0) {
    const int = _value.substring(0, decimalPos).replace(/[^0-9]/g, '');
    const frac = _value
      .substring(decimalPos)
      .replace(/[^0-9]/g, '')
      .substring(0, 2);

    _value = [int, '.', frac].join('');
  } else {
    _value = _value.replace(/[^0-9]/g, '');
  }

  return _value;
}

export function formatDatetime(datetime) {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeStyle: 'short',
    hour12: false,
  }).format(new Date(datetime));
}

export function formatEventTime(datetime) {
  return new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
    timeZone: 'Africa/Lagos',
    timeZoneName: 'short',
  }).format(new Date(datetime));
}

export function formatEventDate(datetime) {
  return new Intl.DateTimeFormat('en-GB', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(datetime));
}

export const formatDatetimeWl = (time, withoutDay, format) => {
  if (time === null || time === undefined || time === '') return time;
  const toISOString = new Date(time).toISOString();

  const dateWithTimezone = DateTime.fromISO(toISOString, {
    zone: 'utc',
    locale: 'en-UK',
  });
  if (format) {
    return dateWithTimezone.toLocaleString(DateTime[format]);
  }
  if (withoutDay) {
    return dateWithTimezone.toLocaleString(DateTime.DATE_FULL);
  }

  return dateWithTimezone.toFormat('DD, T');
};

export const formatDateToOwnFormatWl = (date, format = 'yyyy LLL dd') => {
  if (date === null || date === undefined || date === '') return date;
  const dateString = removeTimeZone(new Date(date));
  const toISOString = new Date(dateString).toISOString();

  const dateWithTimezone = DateTime.fromISO(toISOString, { zone: 'utc' });
  return dateWithTimezone.toFormat(format);
};

export function calculateAdminCharges(price, adminPercentage, fixedFee) {
  const adminFee = (adminPercentage / 100) * price;
  return {
    value: adminFee + fixedFee,
  };
}

export const momentDate = (dateTime, timezone) => {
  const mo1 = moment(dateTime);
  const mo2 = moment(dateTime).tz(timezone);

  const modifiedDate = moment(dateTime);
  modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');

  return modifiedDate.valueOf();
};

export const getAgeCriteriaOptions = () => {
  let ageOptionsArray = [];

  for (let i = 1; i < 100; i++) {
    ageOptionsArray.push({ label: i + '+', value: i });
  }

  return ageOptionsArray;
};

// Function to get time zone details based on a time zone identifier
export const getTimeZoneObj = (timeZone) => {
  if (!timeZone) {
    return null;
  }

  const dateTime = DateTime.now().setZone(timeZone);

  const longTimeZoneName = dateTime.offsetNameLong;

  // Helper function to get abbreviation from a sentence
  const getAbbreviation = (sentence) => {
    return _.chain(sentence)
      .split(' ')
      .map((word) => word[0])
      .join('')
      .value();
  };

  return {
    abbr: getAbbreviation(longTimeZoneName),
    tzName: longTimeZoneName,
    timezone: timeZone,
  };
};

export const getTimezonenameByCountry = (countryIso2 = 'GB', timezone) => {
  const tzs = getTimezoneByCountry(countryIso2);
  if (!tzs.length) return null;

  const getAbbreviation = (tz) =>
    tz.abbreviation === tz.offset
      ? abbreviation[tz.alternativeName] || moment.tz(timezone).zoneAbbr()
      : tz.abbreviation;

  const lastTz = tzs[tzs.length - 1];
  const defaultTz = {
    abbr: getAbbreviation(lastTz),
    tzName: lastTz.alternativeName,
    timezone: lastTz.name,
  };

  if (timezone) {
    const filteredTz = _.filter(tzs, (item) => item.name === timezone);
    const timezoneObj = getTimeZoneObj(timezone);
    if (!filteredTz.length) {
      return _.isEmpty(timezoneObj) ? defaultTz : timezoneObj;
    }

    const lastFilteredTz = filteredTz[filteredTz.length - 1];
    return {
      abbr: getAbbreviation(lastFilteredTz),
      tzName: lastFilteredTz.alternativeName,
      timezone: lastFilteredTz.name,
    };
  }

  return defaultTz;
};

export const formatEventTimeZone = (time = new Date()) => {
  if (time === null || time === undefined || time === '') return time;

  const toISOString = new Date(time).toISOString();
  return DateTime.fromISO(toISOString, { zone: 'utc' }).toLocaleString(
    DateTime.TIME_24_SIMPLE
  );
};

export const removeTimeZone = (date) => {
  const opts = {
    zone: 'utc',
    locale: 'en-UK',
  };
  return DateTime.fromFormat(date.toLocaleString('en-UK'), 'F', opts).toISO();
};

export const setDateToTimeZone = (date = new Date(), removeZone = false) => {
  if (date === null || date === undefined || date === '') return date;
  const dateString = removeTimeZone(new Date(date));

  const isoString = new Date(dateString).toISOString();
  const dateTime = DateTime.fromISO(isoString, { zone: 'utc' }).toISO();

  if (removeZone) {
    return new Date(new Date(dateTime).toISOString().split('.')[0]);
  }
  return dateTime;
};

export const DatetimeWithTimezone = (time, withoutDay, format) => {
  if (time === null || time === undefined || time === '') return time;
  const toISOString = new Date(time).toISOString();

  const dateWithTimezone = DateTime.fromISO(toISOString, { zone: 'utc' });
  if (format) {
    return dateWithTimezone.toLocaleString(DateTime[format]);
  }
  if (withoutDay) {
    return dateWithTimezone.toLocaleString(DateTime.DATE_FULL);
  }

  return dateWithTimezone.toFormat('DD, T');
};

export const compareEventDates = (Date1, Date2, operator = 'less than') => {
  let ValueA = new Date(Date1);
  let ValueB = new Date(Date2);

  if (!ValueA || !ValueB) return false;
  if (operator === 'less than') return ValueA < ValueB;
  if (operator === 'greater than') return ValueA > ValueB;
  if (operator === 'equal to') return ValueA === ValueB;
  if (operator === 'not equal to') return ValueA !== ValueB;
  if (operator === 'less than or equal to') return ValueA <= ValueB;
  if (operator === 'greater than or equal to') return ValueA >= ValueB;
  return true;
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeCaseToSentence = (string) =>
  string
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

export const convertCamelToSentence = (value) => {
  const result = value.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export function filterObject(obj, callback) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key))
  );
}

export const filterArrayOfObjects = (array, callback) => {
  return array.filter((elem) => callback(elem));
};

export function iso3ToIso2(iso3) {
  return sc.getCountries().filter(({ iso }) => iso === iso3)?.[0]?.iso2;
}

export function iso2ToIso3(iso2) {
  return sc.getCountries().filter(({ iso2: iso }) => iso === iso2)?.[0]?.iso;
}

// TODO:Compare Time Zone Dates

export const compareTzDates = (value, countryIso2 = 'GB', _timezone) => {
  const { timezone } = getTimezonenameByCountry(countryIso2, _timezone);
  if (!timezone || !value) return false;
  const targetTimezone = _timezone ? _timezone : timezone;

  return (
    DateTime.now().setZone(targetTimezone).toISO().split('.')[0] <
    DateTime.fromISO(value.toISOString()).toISO().split('.')[0]
  );
};

export const getCurrentTzDate = (countryIso2 = 'GB', _timezone) => {
  const { timezone } = getTimezonenameByCountry(countryIso2, _timezone);
  if (!timezone) return null;
  const targetTimezone = _timezone ? _timezone : timezone;

  const dateTime = DateTime.now().setZone(targetTimezone).toISO().split('.')[0];

  return dateTime;
};

export function isValidURL(str) {
  var pattern = new RegExp(
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
  );
  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
}

export function toColorObject(color) {
  if (!color) {
    return { r: '0', g: '0', b: '0', a: '1' };
  }

  let [r, g, b, a] = color.match(/\d+\.?\d*/g).map(Number);
  a = a !== undefined ? a.toString() : color.startsWith('rgb') ? '1' : '0';
  return { r: r.toString(), g: g.toString(), b: b.toString(), a: a };
}

export function getRGBAColorString({ r = 0, g = 0, b = 0, a = 1 } = {}) {
  return `rgba(${r},${g},${b},${a})`;
}

export const backDate = (date) => {
  const now = new Date();

  if (date === '7') {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  }

  if (date === '30') {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
  }
  if (date === 'PAST YEAR')
    return new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
};

export const isJwtExpired = (token) => {
  if (typeof token !== 'string' || !token)
    throw new Error('Invalid token provided');

  let isJwtExpired = false;
  const { exp } = jwt_decode(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
};

export const forceKeyPressUppercase = (e) => {
  let el = e.target;
  let charInput = e.key;
  if (charInput >= 'a' && charInput <= 'z') {
    // lowercase
    if (!e.ctrlKey && !e.metaKey && !e.altKey) {
      // no modifier key
      let newChar = charInput.toUpperCase();
      let start = el.selectionStart;
      let end = el.selectionEnd;
      el.value =
        el.value.substring(0, start) + newChar + el.value.substring(end);
      el.setSelectionRange(start + 1, start + 1);
      e.preventDefault();
    }
  }
};

export const forceUpperCase = (value, inputName, setFieldValue) => {
  if (inputName === 'eventName') {
    const newValue = value.toUpperCase();
    setFieldValue(inputName, newValue);
  }
};

export function yyyymmddformatDate(inputDate) {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;
}

export function ISOFormatTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error(`Invalid date`);
  }

  const isoString = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  ).toISOString();

  return isoString;
}

export function formatDecimalNumberInput(value) {
  // Remove all characters except digits and a single decimal point
  let cleanedValue = value.replace(/[^\d.]/g, '');

  // Split the cleaned value into integer and decimal parts
  const parts = cleanedValue.split('.');

  // Ensure there's at most one decimal point
  if (parts.length > 2) {
    // If more than one decimal point, take only the first part as the integer part
    cleanedValue = parts[0] + '.' + parts.slice(1).join('');
  }

  // Ensure the decimal part has at most two decimal places
  if (parts[1] && parts[1].length > 2) {
    cleanedValue = parts[0] + '.' + parts[1].substring(0, 2);
  }

  return cleanedValue;
}

export function convertToFloatOrNull(value) {
  // Check if the value is a number
  if (typeof value === 'number') {
    return null;
  }

  // Check if the value is a string
  if (typeof value === 'string') {
    // Check if the string contains a decimal point
    if (value.includes('.')) {
      // Try to parse the string as a float
      const floatValue = parseFloat(value);

      // Check if the parsing was successful and the result is a finite number
      if (!isNaN(floatValue) && isFinite(floatValue)) {
        return floatValue;
      }
    }
  }

  // If the value is neither a number nor a string containing a float, return null
  return null;
}

export function formatDatetimeWithUTC(datetime) {
  const utcDate = new Date(datetime);
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeStyle: 'short',
    hour12: false,
    timeZone: 'UTC',
  }).format(utcDate);
}

export function isNumeric(value) {
  return !_.isNaN(Number(value));
}

export function isNonEmptyString(value) {
  return !isNumeric(value) && _.isString(value) && !_.isEmpty(value);
}

export function newDateByISO(iso) {
  const date = !!iso ? new Date(DateTime.fromISO(getCurrentTzDate(iso))) : null;
  return !!date ? date : new Date();
}

function encodeBase64(input) {
  const keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';

  _.chunk(
    input.split('').map((char) => char.charCodeAt(0)),
    3
  ).forEach((chunk) => {
    let enc1 = chunk[0] >> 2;
    let enc2 = ((chunk[0] & 3) << 4) | (chunk[1] >> 4);
    let enc3 = ((chunk[1] & 15) << 2) | (chunk[2] >> 6);
    let enc4 = chunk[2] & 63;

    if (_.isNaN(chunk[1])) {
      enc3 = enc4 = 64;
    } else if (_.isNaN(chunk[2])) {
      enc4 = 64;
    }

    output += _.chunk(
      [enc1, enc2, enc3, enc4].map((index) => keyStr.charAt(index)),
      4
    )
      .map((arr) => arr.join(''))
      .join('');
  });

  return output;
}

export function encodeCredentials(username, password) {
  const credentials = username + ':' + password;
  const base64Credentials = encodeBase64(credentials);
  return `Basic ${base64Credentials}`;
}

export const generateDynamicUrl = (url, hostname) => {
  if (url.includes('dev.') || url.includes('staging.')) {
    // Extracting the subdomain from the URL
    const subdomain = hostname.replace(/\W+/g, '-').toLowerCase();

    // Constructing the dynamic URL
    return `https://${subdomain}-${url}`;
  }

  // Constructing the dynamic URL for live environment
  return `https://${hostname}.${url}`;
};

export const calculateQuantityAvailable = (
  quantity,
  lockedTickets,
  soldTicketsCount
) => {
  const available = Math.max(
    0,
    Number(quantity) -
      (Math.max(0, Number(lockedTickets)) + Number(soldTicketsCount))
  );

  return available;
};

export function convertTextToHyphenated(inputText) {
  const lowerCaseText = _.toLower(inputText);
  const hyphenatedText = _.replace(lowerCaseText, /[^a-zA-Z0-9]+/g, '-');
  return hyphenatedText;
}

export const getSortedProducts = (products) => {
  return _.orderBy(
    _.sortBy(products, ['ProductPrice', 'ProductType.ProductTypeShortcode']),
    [
      (product) =>
        product?.IsSoldOut ||
        calculateQuantityAvailable(
          product?.Quantity,
          product?.LockedTicket,
          product?.transactionItems_aggregate_TransactionStatus_Quantity
        ) === 0,
    ],
    ['asc']
  );
};

export const convertToBoolean = (input) => {
  const trueTexts = ['true', 'yes', 'y'];
  const falseTexts = ['false', 'no', 'n'];

  // Handle different input types
  if (_.isBoolean(input)) {
    return input;
  }

  if (_.isString(input)) {
    const text = input.toLowerCase();
    if (trueTexts.includes(text)) {
      return true;
    } else if (falseTexts.includes(text)) {
      return false;
    }
  }

  if (_.isNumber(input)) {
    return input !== 0;
  }

  // For other types (arrays, objects, etc.), return false
  if (_.isObject(input) || _.isArray(input)) {
    return false;
  }

  // Default case: return false
  return false;
};

export const getItemTransferTickets = (id, item) => ({
  id,
  ..._.defaults(
    _.pick(item, [
      'productTitle',
      'seatDescription',
      'eventTicketAllocationId',
      'transactionItemId',
      'transferredTicketsId',
      'transferredTicketsDescription',
      'emailAddress',
    ]),
    {
      productTitle: 'N/A',
      seatDescription: 'N/A',
      eventTicketAllocationId: 0,
      transactionItemId: 0,
      transferredTicketsId: null,
      transferredTicketsDescription: 'N/A',
      emailAddress: 'N/A',
    }
  ),
});

export const generateNumberOptions = (start = 1, end = 13) => {
  return _.range(start, end).map((num) => ({
    label: num.toString(),
    value: num,
  }));
};

export function isUUID(uuidString) {
  const schema = yup.string().uuid();

  // Validate the UUID string
  try {
    schema.validateSync(uuidString, { abortEarly: false });
    return true; // Validation successful, UUID is valid
  } catch (error) {
    return false; // Validation failed, UUID is not valid
  }
}

export function fromentTiktokcontents(Product, cartProduct) {
  return _.map(cartProduct, (item) => {
    const product = _.find(Product, { ProductId: item.productId });
    return {
      content_id: item.productId,
      content_name: product ? product.ProductTitle : '',
      quantity: item.quantity,
      price: item.totalItemPrice,
    };
  });
}

export const getFullAddress = (
  VenueTitle,
  venueDescription,
  AddressLine1,
  addressLine2,
  city,
  postCode,
  countryDescription
) => {
  // Helper function to check if a value is not null, undefined, empty, or a blank string
  const isValid = (value) => !_.isNil(value) && !_.isEmpty(value.trim());

  // Split VenueTitle only once to retrieve last element
  const venueParts = VenueTitle?.trim().split(',') || [];
  const lastPart = venueParts.slice(-1)[0]?.trim();

  // Determine country based on last part of VenueTitle or fallback to countryDescription
  const country = lastPart?.length === 2 ? lastPart : countryDescription;

  // Handle venueDescription: use first part of it if it's comma-separated, else use the full description
  const mainTitle = isValid(venueDescription)
    ? venueDescription?.trim().split(',').length > 1
      ? venueDescription?.trim().split(',')[0]
      : venueDescription
    : venueParts[0];

  // Remove AddressLine1 if it matches mainTitle
  const _AddressLine1 =
    isValid(AddressLine1) && AddressLine1 !== mainTitle ? AddressLine1 : null;

  // Create an array of address components, filtering out invalid ones
  const addressComponents = [
    mainTitle,
    _AddressLine1,
    isValid(addressLine2) ? addressLine2 : null,
    isValid(city) ? city : null,
    isValid(postCode) ? postCode : null,
    isValid(country) ? country : null,
  ].filter(isValid);

  // Return VenueTitle if no valid address components are available, otherwise join them
  return addressComponents.length > 0
    ? addressComponents.join(', ')
    : VenueTitle;
};

export const compareArrayOrObjects = (param1, param2) => {
  return JSON.stringify(param1) === JSON.stringify(param2);
};

export function capitalizeKeys(obj) {
  return _.mapKeys(obj, (value, key) => _.upperFirst(key));
}

export const eventCardTextToEllipses = (text = '', len = 60) => {
  if (text === text.toUpperCase()) {
    len = 48;
  } else if (text === text.toLowerCase()) {
    len = 68;
  }

  if (text.length < len) {
    return text;
  }

  return `${text.trim().substring(0, len)}...`;
};

// Passphrase for encryption and decryption
const passphrase = 'F3D2A7E4B9C6F1B8A0D7E9C5B4A2F0C6';

// Encrypt the data
export const encryptData = (data) => {
  const dataToEncrypt = typeof data === 'string' ? data : JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(
    dataToEncrypt,
    passphrase
  ).toString();
  return encryptedData;
};

// Decrypt the data
export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, passphrase);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  try {
    // Try parsing the decrypted data as JSON
    return JSON.parse(decryptedData);
  } catch {
    // If parsing fails, return the decrypted data as a string
    return decryptedData;
  }
};

export function formatDateWithoutTime(datetime) {
  if (!datetime) {
    return 'N/A';
  }
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeZone: 'UTC',
  }).format(new Date(datetime));
}

export const getMinDates = (data, Venue) => {
  if (!_.isEmpty(data)) {
    // Filter events where `hasEnded` is true
    const filteredEvents = data.filter((event) => {
      const hasEnded = compareTzDates(
        setDateToTimeZone(event.EventEndDate, true),
        Venue?.Country?.ISO2,
        Venue?.timeZoneName
      );
      return hasEnded;
    });

    // Get the **maximum** EventEndDate from the filtered events
    const maxEventEndDate =
      _.maxBy(filteredEvents, (event) => event.EventEndDate)?.EventEndDate ||
      null;

    // Find the corresponding event that matches the maxEventEndDate
    const maxEvent = filteredEvents.find(
      (event) => event.EventEndDate === maxEventEndDate
    );

    // Get the **minimum** EventStartDate from the filtered events
    const minEventStartDate =
      _.minBy(filteredEvents, (event) => event.EventStartDate)
        ?.EventStartDate || null;

    // Determine if there are multiple data entries using lodash
    const isMultipleData = _.size(filteredEvents) > 1;

    // Return the results, including minLastEntryTime for the event with the max EventEndDate
    return {
      minEventStartDate,
      maxEventEndDate, // Renamed for clarity
      minLastEntryTime: maxEvent ? maxEvent.lastEntryTime : null, // Return the corresponding lastEntryTime
      isMultipleData, // Indicate whether data contains more than one entry
    };
  } else {
    // Return null values if data is empty
    return {
      minEventStartDate: null,
      maxEventEndDate: null,
      minLastEntryTime: null,
      isMultipleData: false, // Return false when data is empty
    };
  }
};